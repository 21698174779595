<template>
    <div class="foot">
        <!-- 表单 -->
        <div class="proposeBox" id="foot_submit">
            <div class="mainBox proposeMain">
                <el-form ref="form" :rules="rules" :model="proposeForm" label-width="0px" size="small">
                    <el-form-item label="" prop="name">
                        <el-input v-model="proposeForm.name" placeholder="您的姓名" maxlength="4"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="phone">
                        <el-input v-model="proposeForm.phone" placeholder="您的手机号" maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="company">
                        <el-input v-model="proposeForm.company" placeholder="公司名称" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="demand">
                        <el-input v-model="proposeForm.demand" placeholder="需求" maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item class="sClass">
                        <el-button type="primary" @click="submitForm('form')">提交免费使用软件</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="footBox hidden-xs">
            <div class="mainBox footMain">
                <div  class="footMainLeft">
                    <div class="left1">
                        <span>联系我们</span>
                        <span style="color:#fff;font-size:0.14rem;margin-left:0.05rem;">contact us</span>
                    </div>
                    <div class="left2">
                        <span>西安承信网络信息技术有限公司</span>
                    </div>
                    <div class="left3">
                        <img src="@/assets/home/ic1.png" alt="">
                        <span>{{ configData ? configData.customer_mobile : '' }}</span>
                    </div>
                    <div class="left3">
                        <img src="@/assets/home/ic2.png" alt="">
                        <span>{{ configData ? configData.company_email : '' }}</span>
                    </div>
                    <div class="left3">
                        <img src="@/assets/home/ic3.png" alt="">
                        <span>{{ configData ? configData.customer_address : '' }}</span>
                    </div>
                </div>
                <div class="footMainRight">
                    <div class="right1">
                        <span>关注我们</span>
                        <span style="color:#fff;font-size:0.14rem;margin-left:0.05rem;">follow us</span>
                    </div>
                    <div class="right2">
                        <div class="text">
                            <span>客&nbsp;服&nbsp;热&nbsp;线</span>
                            <span>{{ configData ? configData.customer_mobile : '' }}</span>
                            <span>服务时间（周一到周五 8:30-17:30）</span>
                        </div>
                        <div class="rwm" style="margin-right:0.15rem;">
                            <img :src="configData ? imgHead + configData.company_kf_qrcode : ''" alt="">
                            <span>客服咨询</span>
                        </div>
                        <div class="rwm">
                            <img :src="configData ? imgHead + configData.company_gzh_qrcode : ''" alt="">
                            <span>关注官方公众号</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="mainBox bottomBox">
                <span style="margin-right: 10px;">{{ configData ? configData.copyright : '' }}</span>
                <span>
                    <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{configData_2}}</a>
                    <img src="@/assets/ga.png" alt="">
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002002377" target="_blank">{{configData_3}}</a>
                    <!-- <a href="http://old.cxwangluo.com" target="_blank">
                        访问旧官网
                    </a> -->
                </span>
                
            </div>
        </div>
    </div>
</template>

<script>
import {systemConfig,addMessage} from "@/api/index" 

export default {
    data(){
        return {
            imgHead: '',
            configData: {},
            configData_1:'',
            configData_2:'',
            configData_3:'',
            proposeForm: {
                name: '',
                phone: '',
                company: '',
                demand: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的姓名', trigger: 'change' },
                    // { min: 1, max: 4, message: '长度不超过4个字符', trigger: 'change' }
                ],
                phone: [
                    // { type: 'number',required: true, message: '请输入您的手机号', trigger: 'change' }
                    { required: true, message: '请输入您的手机号', trigger: 'change' },
                    {
                        // pattern: /^(((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)|(1[3|4|5|6|7|8|9][0-9]\d{8}))$/,
                        pattern: /^1(3|4|5|7|8|6|9)\d{9}$/,
                        message: "请输入格式正确的手机号码",
                        trigger: "change"
                    },
                ],
                company: [
                    { required: true, message: '请输入公司名称', trigger: 'change' },
                    // { min: 1, max: 30, message: '长度不超过30个字符', trigger: 'change' }
                ],
                demand: [
                    { required: true, message: '请输入您的需求', trigger: 'change' },
                    // { min: 1, max: 50, message: '长度不超过50个字符', trigger: 'change' }
                ]
            }

        }
    },
    mounted(){
        this.imgHead = process.env.VUE_APP_IMG_URL_HEAD
        this.get()
    },
    methods: {
        //系统配置数据
        get(){
            systemConfig({}).then(data => {
                this.configData = data.data;
                let copyright = this.configData.copyright;
                this.configData_1 =  copyright.substring(0, copyright.indexOf('.陕') + 1)
                this.configData_2 = this.configData.icp_beian
                this.configData_3 = this.configData.beian
            })
        },
        post(){
            addMessage({
                name: this.proposeForm.name,
                phone: this.proposeForm.phone,
                company: this.proposeForm.company,
                demand: this.proposeForm.demand
            }).then(data => {
                this.$message({
                    message: '提交成功！',
                    type: 'success'
                })
                this.$refs.form.resetFields();
            })
        },
        //留言提交
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                this.post()
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        }

}
</script>

<style lang="scss" scoped>
.foot{
    .proposeBox{
        width: 100%;
        .proposeMain{
            height: 100%;
        }
    }
    .footBox{
        // height: 2.8rem;
        background-color: #333;
        .footMain{
            height: 100%;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            padding: 0.55rem 0;
            color: #fff;
            .footMainLeft{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .left1{
                    font-size: 0.2rem;
                    color: #F7AF1D;
                }
                .left2{
                    font-size: 0.16rem;
                    margin-top: 0.15rem;
                    margin-bottom: 0.1rem;
                }
                .left3{
                    img{
                        width: 0.2rem;
                        margin-right: 0.05rem;
                    }
                    display: flex;
                    align-items: center;
                    font-size: 0.14rem;
                }
            }
            .footMainRight{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .right1{
                    font-size: 0.2rem;
                    color: #F7AF1D;
                    text-align: end;
                }
                .right2{
                    display: flex;
                    justify-content: flex-end;
                    .rwm{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        // justify-content: space-between;
                        img{
                            width: 1rem;
                        }
                        span{
                            font-size: 0.12rem;
                            margin-top: 0.1rem;
                        }
                    }
                    .text{
                        padding: 0.12rem 0.15rem 0.3rem 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        span{
                            text-align: end;
                        }
                    }
                    .text span:nth-of-type(1){
                        font-size: 0.12rem;
                    }
                    .text span:nth-of-type(2){
                        font-size: 0.28rem;
                        color: #F7AF1D;
                        font-family: Impact;
                    }
                    .text span:nth-of-type(3){
                        font-size: 0.14rem;
                    }
                }
            }
        }
    }

}
</style>
<style lang="scss">
.foot{
    @media only screen and (max-width: 767px) { //手机端
        .footMain{
            width: 100%;
        }
        .proposeBox{
            padding: 15px 0;
            background-color: #3A76D5;
            .sClass{
                text-align: center;
            }
        }
        .bottom{
        width: 100%;
        height: 70px;
        background-color: #262626;
        .bottomBox{
            font-size: 12px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-align: center;
            flex-direction: column;
            img{
                height: 12px;
            }
            a{
                margin-right: 10px;
            }
        }
        
    }
    }
    @media only screen and (min-width: 768px) { //pc端
        .footBox{
            height: 2.8rem;
            .footMain{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .footMainLeft{
                    width: 48%;
                }
                .footMainRight{
                    width: 48%;
                }
            }
        }
        .proposeBox{
            height: 0.93rem;
            background-image: url('@/assets/home/bg3.png');
            background-repeat: no-repeat;
            background-size: 100%;
        }
        .el-form{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-form-item{
            margin-left: 0.15rem;
            margin-bottom: 0;
        }
         .el-button--primary:hover{
            opacity: 0.85;
         }
        }
        .bottom{
        width: 100%;
        height: 0.66rem;
        background-color: #262626;
        .bottomBox{
            font-size: 0.14rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            a{
                margin-right: 0.2rem;
            }
            img{
                height: 0.18rem;
            }
        }
        
    }
    }
    .el-input--small .el-input__inner{
            border-radius: 0;
        }
    .el-button--primary {
            color: #FFF;
            background-color: #F7AF1D;
            border-color: #F7AF1D;
         }
    .el-form-item--small .el-form-item__error{
    color: #fff;
    }
    .el-form-item.is-error .el-input__inner{
        border-color: #fff;
    }
}
</style>