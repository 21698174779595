import request from "@/utils/request";  //导入封装请求的js文件
 
//系统配置
export function systemConfig(params) {
  return request({
    url: "/common/system_config.cms",  //接口路径
    method: "get",  //接口方法
    //headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}

//公共分类
export function cateList(params) {
  return request({
    url: "/common/cate_list.cms",
    method: "get",
    params
  });
}

//首页数据
export function homeData(params) {
  return request({
    url: "/home/home_list.cms",  //接口路径
    method: "get",  //接口方法
    params  //接口参数
  });
} 


//////////////////////////////////产品中心////////////////////////////////
//列表 不带分页
export function productList(params) {
  return request({
    url: "/product/lists.cms",
    method: "get",
    params
  });
}
//详情
export function productDetail(params) {
  return request({
    url: "/product/detail.cms",
    method: "get",
    params
  });
}

export function addReserve(params) {
  return request({
    url: "/product/add_reserve.cms",
    method: "post",
    params
  });
}

//////////////////////////////////解决方案////////////////////////////////
//列表 不带分页
export function solutionList(params) {
  return request({
    url: "/solution/lists.cms",
    method: "get",
    params
  });
}
//详情
export function solutionDetail(params) {
  return request({
    url: "/solution/detail.cms",
    method: "get",
    params
  });
}


//////////////////////////////////行业案例////////////////////////////////
//列表 带分页
export function casesList(params) {
  return request({
    url: "/industry_case/list.cms",
    method: "get",
    params
  });
}
//详情
export function casesDetail(params) {
  return request({
    url: "/industry_case/detail.cms",
    method: "get",
    params
  });
}


//////////////////////////////////新闻中心////////////////////////////////
//列表 带分页
export function newsList(params) {
  return request({
    url: "/news/lists.cms",
    method: "get",
    params
  });
}
//详情
export function newsDetail(params) {
  return request({
    url: "/news/detail.cms",
    method: "get",
    params
  });
}

//////////////////////////////////关于我们////////////////////////////////
//企业简介
export function companyInfo(params) {
  return request({
    url: "/about/company_info.cms",
    method: "get",
    params
  });
}
//企业文化
export function companyCulture(params) {
  return request({
    url: "/about/company_culture.cms",
    method: "get",
    params
  });
}
//发展历程
export function developmentCourse(params) {
  return request({
    url: "/about/development_course.cms",
    method: "get",
    params
  });
}
//合作伙伴
export function partner(params) {
  return request({
    url: "/about/cooperative_partner.cms",
    method: "get",
    params
  });
}
//企业视频
export function companyVideos(params) {
  return request({
    url: "/about/company_videos.cms",
    method: "get",
    params
  });
}
//荣誉资质
export function honor(params) {
  return request({
    url: "/about/honor_qualification.cms",
    method: "get",
    params
  });
}

////////////////////////联系我们///////////////////////////////////////
//获取招聘岗位
export function contactJobs(data) {
  return request({
    url: "/contact_us/jobs.cms",
    method: "get",
    data
  });
}
// 上传简历
export function resumeJob(data) {
  return request({
    url: "/contact_us/resume.cms",
    method: "post",
    data
  });
}


////////////////////////留言///////////////////////////////////////
export function addMessage(data) {
  return request({
    url: "/contact_us/add_leave_message.cms",
    method: "post",
    data
  });
}



export function products(params) {
  return request({
    url: "/home/home_list.cms",  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}
 
export function login(data) {
  return request({
    url: "/api/v1/auth/login",
    method: "post",
    data,
    // post请求接口后面拼接参数
    params: {
      currentOpenId: 'currentOpenId',
      currentCompanyOpenId: 'currentCompanyOpenId'
    }
  });
}