<template>
	<div id="app">
		<el-backtop></el-backtop>
		<TopLogo class="hidden-xs" :telNumber="configData ? configData.customer_mobile : ''"
			:imgSrc="configData ? imgHead + configData.logo : ''" />
		<!-- pc端的首页导航栏 -->
		<div class="hidden-xs navBox">
			<ul class="mainBox navMain">
				<li v-for="(item,index) in navData" :key="item.name"
					:class="activePath == item.path ? 'routerActive' : ''" @click="navChange(item)">
					<!-- <router-link :to="item.path == '/productions' || item.path == '/solutions' ? '' : item.path">{{item.name}}</router-link> -->
					<span>{{item.name}}</span>
					<!-- 导航栏鼠标悬浮卡片  -->
					<div v-if=" item.cardData && item.cardData.length > 0" class="navBoxCard">
						<div class="card_list">
							<span class="card_list_item" v-for="i in item.cardData" :key="i.id"
								@click="pathHandle(index,i.id)">{{i.title}}</span>
						</div>
						<div class="card_img">
							<img v-if="item.path == '/productions'" src="@/assets/proMax.png" />
							<img v-if="item.path == '/solutions'" src="@/assets/solMax.png" />
						</div>
					</div>
				</li>
			</ul>
		</div>

		<!-- 移动端的首页导航栏 -->
		<nav class="visible-xs-block navbar navbar-default navbar-fixed-top">
			<div class="container-fluid">
				<div class="navbar-header">
					<button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
						data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
						<span class="sr-only"></span>
						<span class="icon-bar"></span>
						<span class="icon-bar"></span>
						<span class="icon-bar"></span>
					</button>
					<a class="navbar-brand" href="/home"><img style="height:100%;" alt=""
							:src="imgHead + configData.logo"></a>
				</div>
				<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
					<ul class="nav navbar-nav">
						<li v-for="(item,index) in navData" :key="item.name" @click="navChange(item)">
							<!-- <router-link :to="item.path">{{item.name}}</router-link> -->
							<a v-if=" item.cardData && item.cardData.length > 0" data-target="#" class="dropdown-toggle" data-toggle="dropdown">
								{{item.name}} <span class="caret"></span></a>
							<a v-else :href="item.path">{{item.name}}</a>
							<ul v-if="item.cardData && item.cardData.length > 0" class="dropdown-menu" role="menu">
								<li v-for="i in item.cardData" :key="i.name"><a @click="pathHandle(index,i.id)">{{ i.title }}</a></li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<router-view />
		<!-- 公共底部 -->
		<Foot></Foot>
	</div>
</template>

<script>
	import TopLogo from '@/components/topLogo.vue'
	import Foot from '@/components/foot.vue'

	import { systemConfig, productList, solutionList } from "@/api/index"
	export default {
		name: 'App',
		data() {
			return {
				imgHead: '',
				activePath: '/home',
				navData: [{
						name: '网站首页',
						path: '/home'
					},
					{
						name: '产品中心',
						path: '/productions',
						cardData: []
					}, {
						name: '解决方案',
						path: '/solutions',
						cardData: []
					}, {
						name: '行业案例',
						path: '/cases'
					}, {
						name: '关于我们',
						path: '/aboutUs'
					}, {
						name: '新闻中心',
						path: '/news'
					}, {
						name: '联系我们',
						path: '/contactUs'
					}
				],
				configData: {}
			}
		},
		created() {
			this.resizeFun()
		},
		watch:{
			$route(to,from){
				if (to.path == '/cases/caseDetail'|| to.path == '/cases/industryCases'){
					this.activePath = '/cases'
				} else if (to.path == '/news/newsDetail'|| to.path == '/news/companyNews'|| to.path == '/news/employeeWorld'){
					this.activePath = '/news'
				} else if (to.path == '/contactUs/contactUsF'|| to.path == '/contactUs/recruitment'){
					this.activePath = '/contactUs'
				} else if (to.path == '/aboutUs/companyProfile'|| to.path == '/aboutUs/corporateCulture' || to.path == '/aboutUs/development' || to.path == '/aboutUs/honors' || to.path == '/aboutUs/enterpriseVideo' || to.path == '/aboutUs/cooperativePartner'){
					this.activePath = '/aboutUs'
				} else{
					this.activePath = to.path
				}
			}
		},
		mounted() {
			this.imgHead = process.env.VUE_APP_IMG_URL_HEAD
			//回到顶部
			const el = document.querySelector(".navBox")
			const observer = new IntersectionObserver(
				([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1), { threshold: [1] }
			);
			observer.observe(el);
			this.Aclick()
			
			this.get() //系统配置数据
		},
		components: {
			TopLogo,
			Foot
		},
		methods: {
			get() {
				//系统配置数据
				systemConfig({}).then(data => {
					this.configData = data.data
				})
				//产品中心列表
				productList({}).then(data => {
					this.navData[1].cardData = data.data
					this.navData[1].url = data.data[0].bg_img
				})
				//解决方案列表
				solutionList({}).then(data => {
					this.navData[2].cardData = data.data
					this.navData[2].url = data.data[0].bg_img
				})
			},
			navChange(item) {
				if (item.path !== '/productions' && item.path !== '/solutions') {
					this.$router.push(item.path)
					this.activePath = item.path
				} else {
					this.Aclick()
				}
			},
			//二级页面
			pathHandle(index, id) {
				this.Aclick()
				if (index == 1) { //产品中心
					this.$router.push({
						path: '/productions',
						query: {
							id: id
						}
					})
					
				} else if (index == 2) { //解决方案
					this.$router.push({
						path: '/solutions',
						query: {
							id: id
						}
					})
				}
			},
			Aclick(){
				var a = document.querySelector(".navbar-toggle");
				var aList =  $(".dropdown-menu a")
					Array.prototype.forEach.call(aList, function(aListOne) {
						aListOne.addEventListener('click',function(){
							a.click()
						})})
			},
			//pc端
			resizeFun() {
				let docEle = document.documentElement,
					resizeEvt =
					'orientationchange' in window ? 'orientationchange' : 'resize',
					resizeFun = () => {
						let clientWidth = docEle.clientWidth;
						if (!clientWidth) return;
						// 100是换算系数，相当于1rem=100px
						//1920是作为设计图的基准，按照1920*1080的分辨率
						docEle.style.fontSize = 100 * (clientWidth / 1920) + 'px';
					};
				if (!document.addEventListener) return;
				window.addEventListener(resizeEvt, resizeFun, false);
				window.addEventListener('DOMContentLoaded', resizeFun, false);
				resizeFun();
			},
			//手机
			resizeFunS() {
				let docEle = document.documentElement,
					resizeEvt =
					'orientationchange' in window ? 'orientationchange' : 'resize',
					resizeFun = () => {
						let clientWidth = docEle.clientWidth;
						if (!clientWidth) return;
						// 100是换算系数，相当于1rem=100px
						//1920是作为设计图的基准,按照767的分辨率
						docEle.style.fontSize = 100 * (clientWidth / 467) + 'px';
					};
				if (!document.addEventListener) return;
				window.addEventListener(resizeEvt, resizeFun, false);
				window.addEventListener('DOMContentLoaded', resizeFun, false);
				resizeFunS();
			},
			//pc端导航栏样式变化
		}
	}

</script>

<style scoped lang="scss">
	#app {
		.el-backtop {
			border: 1px solid #409EFF;
		}
		//pc端导航栏吸顶样式
		.is-pinned {
			background-color: rgba(255,255,255, 0.8) !important;
			// border-bottom: 1px solid #3A76D5;
			font-size: 0.19rem;

			.navMain li span {
				color: #333333;
				font-size: 0.19rem;
				text-decoration: none;
				font-size: 0.19rem;
					-moz-transition: all 0.36s ease-in-out;
					-webkit-transition: all 0.36s ease-in-out;
					-o-transition: all 0.36s ease-in-out;
					-ms-transition: all 0.36s ease-in-out;
					transition: all 0.36s ease-in-out;
			}

			.navMain li {
				font-size: 0.19rem;
				cursor: pointer;
			}

			.navMain li:hover {
				a {
					font-weight: 550;
					color: #3A76D5;
					font-size: 0.19rem;
					-moz-transition: all 0.36s ease-in-out;
					-webkit-transition: all 0.36s ease-in-out;
					-o-transition: all 0.36s ease-in-out;
					-ms-transition: all 0.36s ease-in-out;
					transition: all 0.36s ease-in-out;
				}
			}

			.router-link-exact-active {
				color: #3A76D5;
			}
		}

		.navBox {
			background-color: rgba(0, 0, 0, 0.4);
			z-index: 999;
			width: 100%;
			height: 0.8rem;
			position: sticky;
			top: -1px;

			// 导航栏悬浮卡片
			.navBoxCard {
				position: absolute;
				top: 0.8rem;
				opacity: 0;
				width: 5.14rem;
				height: 2.52rem;
				background: rgba(255,255,255, 0.8);
				border-radius: 0px 0px 0.2rem 0.2rem;
				padding: 0.3rem 0.2rem;
				// display: flex;
				// flex-direction: row;
				// align-items: center;
				// justify-content: space-between;
				// z-index: 88;
				display: none;
				.card_list {
					// display: flex;
					float: left;
					width: calc(100% - 2.55rem);
					overflow: scroll;
					height: 100%;
					.card_list_item {
						display: block;
						width: 100%;
						font-size: 0.16rem;
						font-family: PingFang SC;
						font-weight: 400;
						color: #333333;
						line-height: 0.16rem;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						word-break: break-all;
						margin: 0;
						margin-bottom: 0.2rem;
						padding-top: 0.03rem;

						&:hover {
							color: #3A76D5;
							-moz-transition: all 0.36s ease-in-out;
							-webkit-transition: all 0.36s ease-in-out;
							-o-transition: all 0.36s ease-in-out;
							-ms-transition: all 0.36s ease-in-out;
							transition: all 0.36s ease-in-out;
						}
					}
					&:last-child{
						margin-bottom: 0;
					}
				}

				.card_img {
					float: right;
					width: 2.45rem;
					height: 1.42rem;
					background: #FFFFFF;
					border-radius: 0.1rem;
					margin-left: 0.1rem;
					margin-bottom: 0.2rem;

					img {
						width: 2.45rem;
						height: 1.42rem;
						border-radius: 0.1rem;
					}
				}
			}
		}

		.navBox:hover {
			background-color: rgba(255,255,255, 0.8);
			// border-top: 1px solid #3A76D5;
			-moz-transition: all 0.36s ease-in-out;
                -webkit-transition: all 0.36s ease-in-out;
                -o-transition: all 0.36s ease-in-out;
                -ms-transition: all 0.36s ease-in-out;
                transition: all 0.36s ease-in-out;

			.navMain li span {
				color: #333333;
				font-size: 0.19rem;
				// text-decoration: none;
				// -moz-transition: all 0.16s ease-in-out;
                // -webkit-transition: all 0.16s ease-in-out;
                // -o-transition: all 0.16s ease-in-out;
                // -ms-transition: all 0.16s ease-in-out;
                // transition: all 0.16s ease-in-out;
			}

			.navMain li:hover {
				a {
					font-weight: 550;
					color: #3A76D5;
					font-size: 0.19rem;
					-moz-transition: all 0.36s ease-in-out;
					-webkit-transition: all 0.36s ease-in-out;
					-o-transition: all 0.36s ease-in-out;
					-ms-transition: all 0.36s ease-in-out;
					transition: all 0.36s ease-in-out;
				}

				.navBoxCard {
					display: block;
					opacity: 0.9;
					-moz-transition: all 0.36s ease-in-out;
					-webkit-transition: all 0.36s ease-in-out;
					-o-transition: all 0.36s ease-in-out;
					-ms-transition: all 0.36s ease-in-out;
					transition: all 0.36s ease-in-out;
				}
			}

			.router-link-exact-active {
				color: #3A76D5;
			}


		}


		.navMain {
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;
		}

		.navMain li {
			height: 100%;
			line-height: 0.8rem;
			cursor: pointer;
			font-size: 0.19rem;
		}

		.navMain li:hover {
			font-weight: 550;
			-moz-transition: all 0.36s ease-in-out;
                -webkit-transition: all 0.36s ease-in-out;
                -o-transition: all 0.36s ease-in-out;
                -ms-transition: all 0.36s ease-in-out;
                transition: all 0.36s ease-in-out;
		}

		.navMain li span {
			color: #fff;
			font-size: 0.19rem;
		}

		.navbar-default {
			border-radius: 0;
			margin: 0;
		}

		.routerActive {
			color: #fff;
			font-size: 0.19rem;
			font-weight: 600;
		}
	}

</style>

