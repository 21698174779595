
//1. 导入 Vue 和 VueRouter 的包
import Vue from 'vue'
import VueRouter from 'vue-router'
 
//2. 调用Vue.use()函数，把VueRouter安装为Vue的插件
Vue.use(VueRouter)
 
//3. 创建路由的实例对象
const router = new VueRouter({
    mode: "history", 
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/', 
            redirect: '/home'//重定向
        },
        {
            path: '/home',
            component: () => import('@/views/home'),
            meta: {
                metaInfo: {
                    title: "承信网络",
                    keywords: "承信网络",
                    description: "承信网络"
                }
            }
            
        },
        {  //产品中心
            path: '/productions',
            component: () => import('@/views/productions'),
            meta: {
                metaInfo: {
                    title: "产品中心",
                    keywords: "产品中心",
                    description: "产品中心"
                }
            }
        },
        {  //解决方案
            path: '/solutions',
            component: () => import('@/views/solutions'),
            meta: {
                metaInfo: {
                    title: "解决方案",
                    keywords: "解决方案",
                    description: "解决方案"
                }
            }
        },
        {  //行业案例
            path: '/cases',
            redirect: '/cases/industryCases',//重定向
            component: () => import('@/views/cases'),
            meta: {
                metaInfo: {
                    title: "行业案例",
                    keywords: "行业案例",
                    description: "行业案例"
                }
            },
            children: [
                { //全部列表
                    path: '/cases/industryCases',
                    component: () => import('@/components/industryCases'),
                    meta: {
                        metaInfo: {
                            title: "行业案例",
                            keywords: "行业案例",
                            description: "行业案例"
                        }
                    }
                }
              ],
        },
        {  //案例详情
            path:'/cases/caseDetail',
            component:() => import('@/views/caseDetail'),
            meta: {
                metaInfo: {
                    title: "案例详情",
                    keywords: "案例详情",
                    description: "案例详情"
                }
            }
        },
        {  //关于我们
            path: '/aboutUs',
            redirect: '/aboutUs/companyProfile',//重定向
            component: () => import('@/views/aboutUs'),
            meta: {
                metaInfo: {
                    title: "关于我们",
                    keywords: "关于我们",
                    description: "关于我们"
                }
            },
            children: [
                { //公司简介
                    path: '/aboutUs/companyProfile',
                    component: () => import('@/components/aboutUs/companyProfile.vue'),
                    meta: {
                        metaInfo: {
                            title: "关于我们",
                            keywords: "关于我们",
                            description: "关于我们"
                        }
                    }
                },
                { //企业文化
                    path: '/aboutUs/corporateCulture',
                    component: () => import('@/components/aboutUs/corporateCulture.vue'),
                    meta: {
                        metaInfo: {
                            title: "关于我们",
                            keywords: "关于我们",
                            description: "关于我们"
                        }
                    }
                },
                { //发展历程
                    path: '/aboutUs/development',
                    component: () => import('@/components/aboutUs/development.vue'),
                    meta: {
                        metaInfo: {
                            title: "关于我们",
                            keywords: "关于我们",
                            description: "关于我们"
                        }
                    }
                },
                { //荣誉资质
                    path: '/aboutUs/honors',
                    component: () => import('@/components/aboutUs/honors.vue'),
                    meta: {
                        metaInfo: {
                            title: "关于我们",
                            keywords: "关于我们",
                            description: "关于我们"
                        }
                    }
                },
                { //企业视频
                    path: '/aboutUs/enterpriseVideo',
                    component: () => import('@/components/aboutUs/enterpriseVideo.vue'),
                    meta: {
                        metaInfo: {
                            title: "关于我们",
                            keywords: "关于我们",
                            description: "关于我们"
                        }
                    }
                },
                { //合作伙伴
                    path: '/aboutUs/cooperativePartner',
                    component: () => import('@/components/aboutUs/cooperativePartner.vue'),
                    meta: {
                        metaInfo: {
                            title: "关于我们",
                            keywords: "关于我们",
                            description: "关于我们"
                        }
                    }
                },
              ],
        },
        {  //新闻中心
            path: '/news',
            redirect: '/news/companyNews',//重定向
            component: () => import('@/views/news'),
            meta: {
                metaInfo: {
                    title: "新闻中心",
                    keywords: "新闻中心",
                    description: "新闻中心"
                }
            },
            children: [
                { //列表
                    path: '/news/companyNews',
                    component: () => import('@/components/news/companyNews'),
                    meta: {
                        metaInfo: {
                            title: "新闻中心",
                            keywords: "新闻中心",
                            description: "新闻中心"
                        }
                    }
                },
                { //员工天地列表
                    path: '/news/employeeWorld',
                    component: () => import('@/components/news/employeeWorld'),
                    meta: {
                        metaInfo: {
                            title: "新闻中心",
                            keywords: "新闻中心",
                            description: "新闻中心"
                        }
                    }
                }
              ],
        },
        {//新闻详情
            path:'/news/newsDetail',
            component:() => import('@/views/newsDetail'),
            meta: {
                metaInfo: {
                    title: "新闻详情",
                    keywords: "新闻详情",
                    description: "新闻详情"
                }
            }
        },
        {  //联系我们
            path: '/contactUs',
            redirect: '/contactUs/contactUsF',//重定向
            component: () => import('@/views/contactUs'),
            meta: {
                metaInfo: {
                    title: "联系我们",
                    keywords: "联系我们",
                    description: "联系我们"
                }
            },
            children: [
                { //联系我们
                    path: '/contactUs/contactUsF',
                    component: () => import('@/components/contact/contactUsF.vue'),
                    meta: {
                        metaInfo: {
                            title: "联系我们",
                            keywords: "联系我们",
                            description: "联系我们"
                        }
                    }
                },
                { //人才招聘
                    path: '/contactUs/recruitment',
                    component: () => import('@/components/contact/recruitment.vue'),
                    meta: {
                        metaInfo: {
                            title: "联系我们",
                            keywords: "联系我们",
                            description: "联系我们"
                        }
                    }
                }
              ],
        },
        {  //404
            path: '/404',
            component: () => import('@/views/noView')
        },
        {
            path: '*',//匹配未定义的路由
            redirect: '/404'//重定向
        }
 
    ]
})

//重写push方法
const originPush = VueRouter.prototype.push; // 把官方的push方法暂存到originPush这个变量里面
VueRouter.prototype.push = function push (location) { // 参数是调用push方法传进来的参数
    return originPush.call(this, location).catch(() => {});
}





//4. 向外共享路由的实例对象
export default router

