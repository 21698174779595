import Vue from 'vue'
import App from './App.vue'
import store from './store'

// 导入路由模块，拿到路由的实例对象
import router from '@/router/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import $ from 'jquery'
import AMap from 'vue-amap';

import Bootstrap from 'bootstrap'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "../public/scss/index.scss";
import Moment from 'moment'
Vue.prototype.moment = Moment
import Meta from 'vue-meta';
Vue.use(Meta);

Vue.prototype.$ = $
Vue.use(ElementUI);
Vue.use(BootstrapVue)
Vue.use(Bootstrap)
Vue.use(AMap);

Vue.config.productionTip = false

// 初始化vue-amap
AMap.initAMapApiLoader({
	key: 'cfee919d67c677644c597abd446dfaea',
	// 插件集合 （插件按需引入）
	plugin: ['AMap']
	//AMap.Geolocation定位服务插件。融合了浏览器定位、高精度IP定位、安卓定位sdk辅助定位等多种            手段，提供了获取当前准确位置、获取当前城市信息、持续定位(浏览器定位)等功能。
});

//解决路由跳转到页面时，没有在顶部问题
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
	if (to.meta.metaInfo) {
		store.commit("CHANGE_META_INFO", to.meta.metaInfo)
	}
	next()
});

new Vue({
	router,
	store,
	metaInfo() {
		return {
			title: this.$store.state.metaInfo.title,
			meta: [{
				name: "keywords",
				content: this.$store.state.metaInfo.keywords
			}, {
				name: "description",
				content: this.$store.state.metaInfo.description
			}]
		}
	},
	//添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	},
	render: h => h(App),
}).$mount('#app')

