<template>
  <div class="topLogo">
    <div class="mainBox logoBox">
        <div class="logo">
            <img alt="" :src="imgSrc">
            <div class="logoText">
                <span style="font-size: 0.18rem;color: #333333;">
                    数字产业综合服务商
                </span>
                <br>
                <span style="color: #666666;font-size: 0.14rem;margin-top: 0.06rem;">
                    <span>致力于产业信息化</span>&nbsp;&nbsp;<span>产业大数据的发展</span>
                </span>
            </div>
        </div>
        <div class="callUs">
            <span>{{telNumber}}</span> 
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'topLogo',
  props: {
    telNumber: String,
    imgSrc: String
  },
  data(){
    return {

    }
  },
  methods: {
    toHome(){
        this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
.topLogo{
    width: 100%;
    height: 1.3rem;
    .logoBox{
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.logo{
    display: flex;
    justify-content: space-between;
    align-items:center;
    // cursor: pointer;
}
.logoText{
    position: relative;
    padding-left: 0.1rem;
    margin-left: 0.1rem;
}
.logo img{
    height: 0.7rem;
}
.logoText::before{
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: 0;
    content: '';
    width: 1px;
    height: 0.44rem;
    background-color: #e3e3e3;
}
.callUs{
    display: flex; 
    font-family: Impact;
    justify-content: center;
    align-items: center;
}
.callUs span{
    font-size: 0.44rem;
    color: #3A76D5;
}
}
</style>
